var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"table",attrs:{"no-provider-sorting":true,"items":_vm.getPrayers,"fields":_vm.tableColumns,"responsive":"","bordered":"","hover":"","empty-text":"No Prayers found","show-empty":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ 'min-width': '10em' })})})}},{key:"cell(label)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',[_vm._v("Azan")]),_c('hr'),_c('div',[_vm._v("Eqama")])])]},proxy:true},{key:"cell(fajr)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.azanFajr),callback:function ($$v) {_vm.$set(item, "azanFajr", $$v)},expression:"item.azanFajr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamahFajr),callback:function ($$v) {_vm.$set(item, "iqamahFajr", $$v)},expression:"item.iqamahFajr"}})],1)]}},{key:"cell(sunrise)",fn:function(ref){
var item = ref.item;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":Object.assign({}, _vm.timePickerConfig,
        {minDate:item.azanFajr,
        maxDate:item.azanDhuhr})},model:{value:(item.sunrise),callback:function ($$v) {_vm.$set(item, "sunrise", $$v)},expression:"item.sunrise"}})]}},{key:"cell(dhuhr)",fn:function(ref){
      var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.azanDhuhr),callback:function ($$v) {_vm.$set(item, "azanDhuhr", $$v)},expression:"item.azanDhuhr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamahDhuhr),callback:function ($$v) {_vm.$set(item, "iqamahDhuhr", $$v)},expression:"item.iqamahDhuhr"}})],1)]}},{key:"cell(asr)",fn:function(ref){
      var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.azahAsr),callback:function ($$v) {_vm.$set(item, "azahAsr", $$v)},expression:"item.azahAsr"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamahAsr),callback:function ($$v) {_vm.$set(item, "iqamahAsr", $$v)},expression:"item.iqamahAsr"}})],1)]}},{key:"cell(maghrib)",fn:function(ref){
      var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.azanMaghrib),callback:function ($$v) {_vm.$set(item, "azanMaghrib", $$v)},expression:"item.azanMaghrib"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamahMaghrib),callback:function ($$v) {_vm.$set(item, "iqamahMaghrib", $$v)},expression:"item.iqamahMaghrib"}})],1)]}},{key:"cell(isha)",fn:function(ref){
      var item = ref.item;
return [_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.azanIsha),callback:function ($$v) {_vm.$set(item, "azanIsha", $$v)},expression:"item.azanIsha"}})],1),_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig},model:{value:(item.iqamahIsha),callback:function ($$v) {_vm.$set(item, "iqamahIsha", $$v)},expression:"item.iqamahIsha"}})],1)]}},{key:"cell(action)",fn:function(ref){
      var item = ref.item;
return [_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateDatePrayer(item)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_c('span',[_vm._v("Save")])],1),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deletePrayer(item._id)}}},[_c('feather-icon',{attrs:{"icon":"XOctagonIcon"}}),_c('span',[_vm._v("Delete")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }