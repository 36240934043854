<template>
  <b-table
    ref="table"
    :no-provider-sorting="true"
    :items="getPrayers"
    :fields="tableColumns"
    responsive
    bordered
    hover
    empty-text="No Prayers found"
    show-empty
  >
    <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ 'min-width': '10em' }"
      >
    </template>
    <template #cell(label)>
      <div class="text-center">
        <div>Azan</div>
        <hr>
        <div>Eqama</div>
      </div>
    </template>
    <template #cell(fajr)="{item}">
      <b-form-group>
        <flat-pickr
          v-model="item.azanFajr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
      <b-form-group>
        <flat-pickr
          v-model="item.iqamahFajr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
    </template>

    <template #cell(sunrise)="{item}">
      <flat-pickr
        v-model="item.sunrise"
        class="form-control"
        :config="{
          ...timePickerConfig,
          minDate:item.azanFajr,
          maxDate:item.azanDhuhr,
        }"
      />
    </template>

    <template #cell(dhuhr)="{item}">
      <b-form-group>
        <flat-pickr
          v-model="item.azanDhuhr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
      <b-form-group>
        <flat-pickr
          v-model="item.iqamahDhuhr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
    </template>

    <template #cell(asr)="{item}">
      <b-form-group>
        <flat-pickr
          v-model="item.azahAsr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
      <b-form-group>
        <flat-pickr
          v-model="item.iqamahAsr"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
    </template>

    <template #cell(maghrib)="{item}">
      <b-form-group>
        <flat-pickr
          v-model="item.azanMaghrib"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
      <b-form-group>
        <flat-pickr
          v-model="item.iqamahMaghrib"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
    </template>

    <template #cell(isha)="{item}">
      <b-form-group>
        <flat-pickr
          v-model="item.azanIsha"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
      <b-form-group>
        <flat-pickr
          v-model="item.iqamahIsha"
          class="form-control"
          :config="timePickerConfig"
        />
      </b-form-group>
    </template>
    <template #cell(action)="{item}">
      <b-button
        variant="primary"
        class="mb-1"
        @click="updateDatePrayer(item)"
      >
        <feather-icon
          icon="SaveIcon"
        />
        <span>Save</span>
      </b-button>

      <b-button
        size="sm"
        variant="danger"
        @click="deletePrayer(item._id)"
      >
        <feather-icon
          icon="XOctagonIcon"
        />
        <span>Delete</span>
      </b-button>
    </template>
  </b-table>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'PrayersTable',
  components: {
    flatPickr,
  },
  props: {
    getPrayers: { type: Function, default: () => [] },
    entityId: { type: Number, default: null },
    updateDatePrayer: { type: Function, default: () => null },
    setEntityIdInDayPrayerTimes: { type: Function, default: () => null },
    deletePrayer: { type: Function, default: () => null },
  },
  data() {
    return {
      selectedDatePrayerTimes: null,
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i K',
        altInput: true,
        altFormat: 'h:i K',
      },
      tableColumns: [
        {
          key: 'date', formatter: v => this.$moment(v).format('ddd,D MMM'),
        },
        { key: 'label' },
        { key: 'fajr' },
        { key: 'sunrise' },
        { key: 'dhuhr' },
        { key: 'asr' },
        { key: 'maghrib' },
        { key: 'isha' },
        { key: 'action' },
      ],
    }
  },
}
</script>
<style lang="scss">

</style>
