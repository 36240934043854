<template>
  <div>
    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="6">
              <month-filter :month.sync="inpoCriteria.dateRange" />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <prayers-table
            ref="parentTable"
            v-bind="{
              getPrayers,
              entityId:inpoCriteria.id,
              updateDatePrayer,
              setEntityIdInDayPrayerTimes,
              deletePrayer,
            }"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import PrayersTable from '@/common/components/Prayers/PrayersTable.vue'
import MonthFilter from '@/common/components/Prayers/MonthFilter.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'PrayersList',
  components: {
    MonthFilter,
    SearchButton,
    PrayersTable,
  },
  data() {
    return {
      inpoCriteria: {
        id: this.$store.getters['mainEntity/getEntityId'],
        dateRange: [
          this.$moment().startOf('month').format('YYYY-MM-DD'),
          this.$moment().endOf('month').format('YYYY-MM-DD'),
        ],
      },
    }
  },
  setup() {
    const { successfulOperationAlert, confirmDelete } = handleAlerts()
    return {
      successfulOperationAlert, confirmDelete,
    }
  },
  methods: {
    getPrayers() {
      return this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/prayers-by-entity`,
        {
          id: this.inpoCriteria.id,
          from: this.inpoCriteria.dateRange[0],
          to: this.inpoCriteria.dateRange[1],
        })
        .then(res => {
          const prayersTimes = res.data.data
          return prayersTimes || []
        })
        .catch(() => [])
    },

    updateDatePrayer(dayPrayersTimes) {
      const newPrayerTimes = this.setEntityIdInDayPrayerTimes(dayPrayersTimes)

      this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/update-prayer-id`, newPrayerTimes).then(() => {
        this.successfulOperationAlert('Prayers Times are updated successfully')
      })
    },

    setEntityIdInDayPrayerTimes(dayPrayersTimes) {
      dayPrayersTimes.entityId = this.entityId
      // eslint-disable-next-line no-underscore-dangle
      dayPrayersTimes.prayerId = dayPrayersTimes._id
      return dayPrayersTimes
    },

    deletePrayer(id) {
      this.confirmDelete().then(() => {
        this.$http.post(`${process.env.VUE_APP_PRAYERS_BASE_URL}/delete-prayer-id`, {
          prayerId: id,
          entityId: this.$store.getters['mainEntity/getEntityId'],
        })
          .then(() => {
            this.successfulOperationAlert('Prayer is deleted successfully')
            this.refreshTable()
          })
      })
    },

    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
